import {
  BoundingSphere,
  Cartesian3,
  destroyObject,
  Matrix4,
  Model,
  Transforms,
} from "../../../Core/cesium/Source/Cesium.js";

const ARROW_PIXEL_SIZE = 30;
const scratchPosition = new Cartesian3();
const scratchBoundingSphere = new BoundingSphere();

function scaleInPixels(positionWC, radius, scene) {
  scratchBoundingSphere.center = positionWC;
  scratchBoundingSphere.radius = radius;

  return scene.camera.getPixelSize(
    scratchBoundingSphere,
    // @ts-ignore
    scene.context.drawingBufferWidth,
    // @ts-ignore
    scene.context.drawingBufferHeight
  );
}

class SliceArrow {
  constructor(options) {
    this._side = options.side;
    this._oppositeSide = options.oppositeSide;
    this._color = options.color;
    this._positionUpdateCallback = options.positionUpdateCallback;
    this._scratchModelMatrix = new Matrix4();
    this._scratchPosition = new Cartesian3();
    this._scene = options.scene;

    (async () => {
      const model = await Model.fromGltfAsync({
        show: true,
        id: this._side,
        url: options.uri,
        color: options.color,
        cull: false,
      });

      this._model = model;
      options.primitiveCollection.add(this._model);
      this.update();
      if (this._model.ready) {
        this._determineEntityModelScale(ARROW_PIXEL_SIZE);
      }
    })();
  }

  update() {
    if (!this._model) {
      return;
    }

    const position = this._positionUpdateCallback(this._side);

    this._model.modelMatrix = Transforms.eastNorthUpToFixedFrame(position, undefined, this._scratchModelMatrix);
  }

  get side() {
    return this._side;
  }

  get oppositeSide() {
    return this._oppositeSide;
  }

  get originalColor() {
    return this._color;
  }

  set color(color) {
    if (!this._model) {
      return;
    }

    this._model.color = color;
  }

  set show(val) {
    if (!this._model) {
      return;
    }

    this._model.show = val;
  }

  get position() {
    return Matrix4.getTranslation(this._scratchModelMatrix, this._scratchPosition);
  }

  _determineEntityModelScale(desiredPixelSize) {
    if (!this._model) {
      return;
    }

    const scene = this._scene;
    // @ts-ignore
    const context = scene.context;

    const maxPixelSize = Math.max(context.drawingBufferWidth, context.drawingBufferHeight);
    const modelMatrix = this._model.modelMatrix;

    scratchPosition.x = modelMatrix[12];
    scratchPosition.y = modelMatrix[13];
    scratchPosition.z = modelMatrix[14];

    const boundingSphere = this._model.boundingSphere;

    const radius = boundingSphere.radius / this._model.scale;

    const metersPerPixel = scaleInPixels(scratchPosition, radius, scene);

    // metersPerPixel is always > 0.0
    const pixelsPerMeter = 1.0 / metersPerPixel;
    const diameterInPixels = Math.min(pixelsPerMeter * (2.0 * radius), maxPixelSize);

    // @ts-ignore
    this._model.scale = desiredPixelSize / diameterInPixels;
  }

  destroy() {
    this._scene.primitives.remove(this._model);

    destroyObject(this);
  }
}

export default SliceArrow;